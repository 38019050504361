$pink = #E81D7A;

.filters
  .headlineLine
    position relative
    @media (max-width 500px)
      margin-bottom 20px
    h1
      display inline-block
      margin-left 0
    h1
    > *
      margin-bottom 0
      line-height 34px
      @media (max-width 500px)
        margin-top 0
    .choicesFilterWrap
      line-height unset

button.filter
  background url("/images/filter.png") 50% 50% no-repeat
  display inline-block
  transition transform .6s ease-in-out
  padding 0
  margin 0 3px
  width 17px
  height @width
  border none
  outline none
  cursor pointer
  &:hover,
  &.active
    -webkit-transform rotate(100deg)
    transform rotate(100deg)
  .mobile &
    width 27px
    margin-right 20px

.sort.mobile
  display none
  .tablet &
  .mobile &
    display block
    > *
      padding 0
      height 30px
      width @height
      line-height @height
      font-weight bold
      img
        vertical-align middle
        margin 0 5px
        width 18px
        height 18px
  .mobile &
    margin-left auto
    min-width 60px
    margin-bottom 0
    margin-top 0
.sort
  display table
  float right
  margin 6px 0 0
  .tablet &
  .mobile &
    display none
  &.favoritePage
    display inline-block
    border-bottom 1px solid rgba(0, 0, 0, 0.07)
    margin 10px 0 20px
    width 100%
    a
    span
      @media(min-width 1030px)
        &:not(:first-child)
          border-left none
    a
      display inline-block
      padding 0
      line-height 28px
      padding-bottom 5px
      span
        display inline-block
        font-weight bold
        font-size 14px
    @media (max-width 1030px)
      a.activated_sort
        &:after
          content ''
          position absolute
          left 0
          top 130%
          width 100%
          border-bottom 6px solid  #E81D7A
      > *
        height 14px
    > *
      position relative
      color #000
      background-color inherit
      text-transform uppercase
      font-size 14px
      margin-right 40px
      cursor pointer
      &:hover
        color #E81D7A
      &:active
        text-decoration none
      &.activated_sort
        background-color unset
        background unset
        box-shadow none
        span
          color #E81D7A
        @media (min-width 1030px)
          &:after
            content ''
            position absolute
            left 0
            top 100%
            width 100%
            border-bottom 6px solid #E81D7A
  a
  span
    @media(min-width 1030px)
      &:not(:first-child)
        border-left 1px solid #4A90E2
  > *
    display table-cell
    text-align center
    height 24px
    color #fff
    font-size 14px
    line-height @height
    background-color #4A90E2
    padding 0 9px
    cursor pointer
    &:first-child
      border-top-left-radius 12px
      border-bottom-left-radius 12px
    &:last-child
      border-top-right-radius 12px
      border-bottom-right-radius 12px
      margin-left -4px
    &:hover
      opacity .8
    &:active
      text-decoration none
    &.activated_sort
      background linear-gradient(89.79deg, #A7F5EB 0.16%, #A6CAF5 99.81%)
      box-shadow inset 0 1px 3px rgba(0, 0, 0, 0.25)
      color #000
      padding 0 11px
      &:hover
        opacity .8

#globalSelect
  display inline-table
  select
    height 25px
    background-color #0485AC
    background-image url("/images/arrow_white.png")
    background-position 97% 70%
    background-size 19px
    background-repeat no-repeat
    color #fff
    border-radius 3px
    border none
    outline none
    margin 0 15px 10px 0
    width 160px
    padding-left 7px
    font-size 14px
    cursor pointer
    -webkit-appearance none
    -moz-appearance none
    appearance none
    &:hover
      background-color #4EA9C4
    @media (max-width 500px)
      margin 0 10px 10px 0
      width 140px
      font-size 13px
    .allGenres
      background-color #eee
      color $pink
  option
    background-color #fff
    color #545454
    font-size 13px

#globalChoice
  display none
  position absolute
  top 50px
  left 0
  right 10px
  box-shadow 0 3px 16px rgba(10, 10, 10, 0.32)
  background-color #F2F2F2
  border-radius 7px
  z-index 999
  padding 30px 20px
  box-sizing border-box
  @media (max-width 1030px)
    max-height 230px
    overflow auto
  .mobile &
    right 0
  .list,
  .list_collections
    padding-bottom 10px
    margin-bottom 20px
    column-width 120px
    column-gap 5px
    min-height 28px
    line-height @min-height
    position relative
    overflow hidden
    .choice
    .more
    .globalTitle,.globalTitle_collections
      color #000
      cursor pointer
      font-size 14px
      padding 0 8px
      max-width 126px
      &:hover
        color $pink
    .choice
      display none
      &.show
        display block
      &.choice_inactive
        pointer-events none
        opacity 0.4
    .choice_active
      display block
      background-color #4A90E2
      border-radius 3px
      color #fff
      &:hover
        color #fff
    &.opened
      .choice
        display block
      .more
        &> *
          display none
        .hideMore
          display initial
    .more
      display block
      color $pink
      .hideMore
        display none
    .globalTitle,.globalTitle_collections
      font-weight 700
      color #000
      display block
      &.choice_active
        color #fff

  .list_collections
    column-width 187px
    margin-bottom 0
    .mobile &
      column-width 147px
      @media (max-width 350px)
        column-width 127px
    .choice
    .globalTitle,.globalTitle_collections
      max-width 187px
      .mobile &
        max-width 147px
        @media (max-width 350px)
          max-width 127px
    .choice
      &.activate
        display block
        background-color #4a90e2
        border-radius 3px
        a
          color #fff
    .globalTitle_collections.activate
      background-color #4a90e2
      border-radius 3px
      a
        color #fff

  .apply
    width 134px
    height 40px
    border-radius 9999px
    margin-right 14px
    background-color $pink
    color #fff
    font-weight 700
    border none
    cursor pointer
    &:hover
      opacity .8
  .clear
    border none
    background transparent
    color $pink
    font-weight 700
    cursor pointer
    img
      vertical-align bottom
      padding-right 5px
    &.choice_inactive
      pointer-events none
      opacity 0.4
    .dashed
      vertical-align top

    &.collapsedGenreBoxOpen
    &.collapsedCountryBoxOpen
      max-height initial
      padding-bottom 10px
      .moreGenre:after, .moreCountry:after
        content 'Скрыть'
        height 20px
        bottom -1px
  .dashed
    border-bottom dashed 1px $pink
    margin-bottom -1px
  .close
    position absolute
    right 20px
    top 0
    cursor pointer

.choicesFilterWrap
  display flex
  min-width 300px
  span
    &:first-child
      margin-right 10px
.choicesFilter
  position relative
  background-color #4A90E2
  border-radius 3px
  color #fff
  padding 3px 10px 3px 6px
  margin-right 5px
  @media (max-width 500px)
    display flex
    align-items center
    margin 5px 0 0
    line-height unset!important
  img
    margin-right 5px
    cursor pointer
    width 10px
    height @width