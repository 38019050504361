ul.buttons-box
  li
    border-radius($button-box-item-border-radius)
    overflow hidden
    inline-block()

    a
      letter-spacing 0.2px
      inline-block()
      text-decoration $button-box-item-text-decoration
      opacity $button-box-item-opacity
      padding $button-box-item-padding
      background $button-box-item-bg-color
      color $button-box-item-text-color
      border $button-box-item-border
      filter unquote('alpha(opacity=70)')

      &:hover
        opacity $button-box-item-hover-opacity
        background $button-box-item-hover-bg-color
        color $button-box-item-hover-text-color
        border $button-box-item-hover-border
        text-decoration $button-box-item-hover-text-decoration

        filter unquote('alpha(opacity=100)')

ul.buttons-box
  &.collapsed
    &:after
      cursor pointer
      content $button-box-collapsed-text
      inline-block()
      border-radius($button-box-item-border-radius)
      overflow hidden
      letter-spacing 0.2px
      inline-block()
      text-decoration none
      padding $button-box-item-padding
      border $button-box-item-border
      background $button-box-item-bg-color
      color $button-box-item-text-color
      vertical-align top

    li
      display none
      &.pinned
        inline-block()

ul.menu-box
  &.collapsed
    &:after
      cursor pointer
      content $button-box-collapsed-text
      display inline-block !important
      border-radius($button-box-item-border-radius)
      overflow hidden
      letter-spacing 0.2px
      text-decoration none
      padding $button-box-item-padding
      border $button-box-item-border
      background $button-box-item-bg-color
      color $button-box-item-text-color
      vertical-align top

    li
      display none !important
      &.pinned
        display inline-block !important