body.tablet
  .box
    .right
      .shared_buttons
        padding 0
    &.collectionTop
      .left
        .item.main
          margin 0 10px
          .tags
            .button_tags
              margin-top 0
        .button
          margin-bottom 0