.plateItems
  text-align justify
  font-size 0
  line-height .1em
  padding $plate-padding
  margin $plate-margin
  &.favoritePage
    text-align unset
    .tablet &
      text-align unset!important
    .tablet &,
    .mobile &
      margin 0 10px
    .mobile &
      text-align justify
    li.item
      margin-right 15px
      @media (max-width 700px)
        margin-right 0
  &.circles
    li
      .cover,
      img
        border-radius 90px!important
  > *
    font-size 1rem
    line-height 1.2em
    display inline-block
    vertical-align top
  &:after
    content ''
    display inline-block
    width 100%

  .item
    position relative
    width 100%
    max-width 190px
    padding 0
    display inline-block
    background $plate-item-bg
    margin $plate-item-margin
    text-align left
    font-size $plate-item-font-size
    font-weight $plate-item-font-weight
    text-decoration $plate-item-text-decoration
    @media (max-width 1020px)
      max-width 170px
    @media (max-width 350px)
      max-width 140px
    &:hover
      .title
        color $link-text-color-hover
    &.circles
      .cover,
      img
        border-radius 95px!important
    .cover
      position relative
      display block
      line-height 0
      border-radius 4px
      box-sizing border-box
      box-shadow 0 5px 10px rgba(0, 0, 0, 0.150136)
      img
        border-radius 4px
        width 100%
        height 100%
        @media (min-width 1030px)
          width 190px
          height @width

    .title
      display inline-block
      width 100%
      margin-top 17px
      margin-bottom 10px
      max-height 50px
      color #000
      font-style normal
      font-weight normal
      font-size 14px
      line-height 16px
      text-align center
      overflow hidden
      text-overflow ellipsis
      max-width 100%

    &.clear
      height 0
      margin 0
      overflow hidden

  &.kineticList
    .linkColor
      text-decoration none
    .item
      margin-right 21px

.kinetic
  position relative
  .kineticLeft
    position absolute
    left 10px
    .mobile &
      left 0
    img
      margin-right 4px
  .kineticRight
    position absolute
    right 10px
    .mobile &
      right 0
    img
      margin-left 4px
  .kineticRight
  .kineticLeft
    display flex
    justify-content center
    top 26%
    z-index 9
    width 50px
    height @width
    background #fff
    color #E81D7A
    opacity 0.3
    box-shadow 0 4px 10px rgba(0, 0, 0, 0.25)
    border-radius 100%
    padding 0
    cursor pointer
    img
      width 12px

  .kineticCenter
    overflow hidden
  .kineticList
    white-space nowrap
    font-size 0
    position relative
    list-style none
    margin 0
    > *
      white-space normal
  .plateItems
    &:after
      display none

  &.is-scroll-right
    .kineticRight
      opacity 1
  &.is-scroll-left
    .kineticLeft
      opacity 1

.desktop-sidebar
  .societyIcons
    padding 15px
    text-align center
    line-height 10px
    min-height 37px
    .society
      display flex
      justify-content space-evenly
      a
        outline none
        width 18px
        height 18px
      img
        cursor pointer
        width inherit
        height inherit


  .plateItems
    padding 0
    text-align justify
    margin-bottom 0
    font-size 0
    li
      inline-block()
      margin-right 6px
      margin-bottom 2px
      a
        letter-spacing 0.2px
        inline-block()
        text-decoration none
        padding 5px 11px 5px 23px
        font-size 14px
        line-height 16px
        color white
      &.item
        position relative
        width 130px
        margin 6px 0
        padding 1px
        margin-top 0
        &:last-child
          margin-left 5px
        &.clear
          height 0!important
          margin 0!important
        &:hover
          cursor pointer
          .title
            color $link-text-color-hover
        a
          padding 0
          background transparent
          .cover
            width 100%
            height 100%
            img
              width 130px
              height 130px
              border-radius 4px
              box-shadow 0 3px 10px rgba(0, 0, 0, 0.150136)



.desktop-sidebar
  .plateItems
    &.books
      li
        a.item
          &:hover
            .title
              color transparent

.collectionTop
  @media (min-width 500px)
    &.box
      display flex
      justify-content space-between
    overflow hidden
    margin-top 17px
  .mobile &
    margin-top 10px
  &.circles
    img
      border-radius 95px!important
  &.collectionPug
    position relative
    height 100%
    border-radius 4px
    .mobile &
      border-radius 0
    .plug
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      overflow hidden
      z-index 0
      &:after
        content ''
        position absolute
        top 0
        left 0
        width 100%
        height @width
        background-color rgba(0,0,0,.3)
      img
        width 100%
        filter blur(20px)
        .mobile &
          height 100%
  .left
    display flex
    padding 20px
    width 100%
    z-index 1
    .mobile &
      padding 10px 0 10px
    img
      min-width 200px
      border-radius 4px
      z-index 1
      .mobile &
        min-width 98px
        width @min-width
        height @width
    .tags
      margin-bottom 10px
  .right
    position absolute
    right 20px
    bottom 10px
    z-index 1
  .main
    margin-left 20px
    z-index 1
    .mobile &
      margin-left 10px
      width 100%
    h1
      margin 0
      text-align start
      font-style normal
      font-weight bold
      font-size 30px
      line-height 40px
      color #fff
      .mobile &
        font-size 20px
        line-height 20px
        margin-bottom 13px