.radio
  text-align justify
  font-size 0
  line-height .1em
  padding $plate-padding
  margin $plate-margin
  > *
    font-size 1rem
    line-height 3.6em
    display inline-block
    vertical-align top
  &:after
    content ''
    display inline-block
    width 100%

  li
    overflow hidden
    inline-block()
    margin-right 6px
    margin-bottom 2px

  .item
    width 100%
    padding 0
    display inline-block
    background $plate-item-bg
    margin $plate-item-margin
    text-align center
    max-width 190px
    font-size $plate-item-font-size
    font-weight $plate-item-font-weight
    text-decoration $plate-item-text-decoration
    position relative
    .mobile &
      max-width 140px
    .cover
      position relative
      display block
      line-height 0
      border-radius 4px
      img
        width 100%
        height 100%
        border-radius 4px
            
    .title
      display inline-block
      width 100%
      margin-top 12px
      margin-bottom 8px
      max-height 50px
      color #000
      font-style normal
      font-weight 400
      font-size 14px
      line-height 16px
      text-align center
      overflow hidden
      text-overflow ellipsis
    &.clear
      height 0
      margin 0
      overflow hidden
    .shadow
      display none
      position absolute
      width 100%
      height 100%
      top 0
      left 0
      background #cceef7
      opacity .8
      border-radius 4px
      i
        width 25px
        height 25px
        position absolute
        display inline-block
        top 50%
        margin-top -11px
        left 50%
        margin-left -11px
        sprite2Crop(1,0,-9,-7)
        background-repeat no-repeat
    :not(.mobile)
    :not(.tablet)
      &:hover
        .shadow
          display block
    &.play
      .shadow
        display block
        opacity 0.9
        i
          sprite2Crop(2,0,-7,-7)
      &.loading
        i
          background url('/images/spiner.gif') #E81D7A 50% 50% no-repeat
          border-radius 25px
          width 25px
          height 25px
    &.pause
      .shadow
        display block
        i
          sprite2Crop(1,0,-9,-7)


.desktop-sidebar
  .radio
    padding 0
    font-size 0
    li
      width 130px
      overflow hidden
      position relative
      .cover
        width 100%
        height 100%
        img
          width 130px
          height 130px
          border-radius 4px
          box-shadow 0 3px 10px rgba(0, 0, 0, 0.150136)

.player-controls
  &.radioControll
    .download
    .repeat
    .shuffle
      display none
    .control
      .prev
      .next
        display none

    .pause
    .play
      margin-left 40px
.progress
  &.radioControll
    display none