#bannerMobile
  display none
  position relative
  text-align center
  z-index 999
  margin-bottom -7px
  .closeAss
    position absolute
    width 30px
    height @width
    top 10px
    right 10px
    background url("/images/promo/banners_mobile/close.svg")
  @media screen and (orientation: portrait)
    img
      width 100%