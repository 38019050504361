.header-main
  position relative
  display flex
  height 100%
  align-items center
  .logoContainer
    margin 0 auto
    cursor pointer
    .logoSmall
      display none
    .logoLarge
      width 503px
    .mobile &
      .logoLarge
        display none
      .logoSmall
        display block
  .link
    position absolute
    right 170px
    width 100%
    height 100%
    .tablet &
    .mobile &
      right 70px