$item-height = 62px
.module-layout
  padding 1px
  .tablet &
  //.mobile &
    .item.main
      margin 0 10px 15px
  .mobile &
    padding 0

  &.error
    padding 20px 19px 70px 19px

  .songs
    margin 5px 0
    .tablet &
      margin 5px 10px 0
    &.index
      .mobile &
        .item
          padding 0

    .item
    .itemSpecial
      padding 0 10px
      cursor pointer
      border-bottom 1px solid #f6f6f6
      position relative
      height $item-height
      @media (max-width 1030px)
        &:hover,
        &:focus
          outline 0
          outline-offset 0
      @media (max-width 750px)
        padding 0 15px 0 10px
        margin-right 0
        margin-left 0
      @media (max-width 500px)
        padding 0
      &:last-child
        border-bottom none
      &:hover
        .inPlaylists,
        .remove_playlist_track
          background url("/images/svg/add_inPlaylists.svg") 50% 50% no-repeat
          width 31px
          height @width
          margin-left 5px
        .remove_playlist_track
          margin-right 0
          margin-left 0
          background url("/images/svg/remove_playlist_track.svg") 50% 50% no-repeat
        .inPlaylists
          margin-right 5px
          margin-left 0
        @media (max-width 750px)
          .inPlaylists
            margin-left 5px
      @media (max-width 1030px)
        .inPlaylists,
        .remove_playlist_track
          background url("/images/svg/add_inPlaylists.svg") 50% 50% no-repeat
          width 31px
          height @width
          margin-left 5px
        .remove_playlist_track
          margin-right 0
          margin-left 0
          background url("/images/svg/remove_playlist_track.svg") 50% 50% no-repeat
        .inPlaylists
          margin-right 5px
          margin-left 0
      .favoriteIco
        width 19px
        height @width
        margin-right 20px
        margin-left 10px
        background url(/images/svg/like-1.svg) 50% 50% no-repeat
        .mobile &
          margin-right 10px
        &.activated
          background url(/images/svg/like-2.svg) 50% 70% no-repeat
      .tablet &
      .mobile &
        padding 0 10px
      &.activeFirstLine
        background #F2F2F2
        box-shadow 0 2px 6px rgba(0, 0, 0, 0.201483)
        border-radius 2px
      &.no_file
        opacity 0.1
        filter unquote('alpha(opacity=10)')
        .actions
          display none
      &.load
        opacity 0.5
        filter unquote('alpha(opacity=50)')
      &.error
        opacity 0.5
        filter unquote('alpha(opacity=50)')
        .play
          position relative
          background url(/images/error.png) 50% 50% no-repeat !important
          border-radius 25px
      &:nth-child(-n+4)
        .inPlaylists
          .dropdown-playlists
            top 50px!important
      &:nth-last-child(-n+7)
        .inPlaylists
          .dropdown-playlists
            top unset
            bottom 10px


      .inPlaylists
        .dropdown-playlists
          position absolute
          width 100%
          max-width 290px
          max-height 311px
          height fit-content
          top 50px
          right 95px
          background #fff
          color #000
          box-sizing border-box
          box-shadow 0 3px 20px rgba(0, 0, 0, 0.25)
          border-radius 5px
          font-size 13px
          text-align left
          display none
          z-index 9
          @media (max-width 750px)
            right 45px
          @media (max-width 500px)
            right 15px
            &:nth-last-child(-n+7)
              bottom 50px
          ul
            margin-top 15px
            max-height 240px
            min-height 35px
            overflow-y auto
            scrollbar-color #5A5959 #FFFFFF
            scrollbar-width thin
            &:before
              content 'Добавить в плейлист'
              display block
              font-weight bold
              font-size 16px
              line-height 24px
              margin 0 20px
              padding-bottom 10px
              border-bottom 1px solid #ec4692
            &::-webkit-scrollbar
              width 5px
            &::-webkit-scrollbar-thumb
              background-color #5A5959
            li
              display block
              font-weight bold
              font-size 14px
              width 93%
              line-height 40px
              padding-left 20px
              overflow hidden
              text-overflow ellipsis
              &:hover
                background #f2f2f2
              span
                display block
                max-width 180px
                text-overflow ellipsis
                overflow hidden
                white-space nowrap
        .create_playlist
          position relative
          font-weight bold
          font-size 14px
          line-height 30px
          margin 0 0 5px 20px
          padding 10px 0 5px 25px
          &:before
            content ''
            position absolute
            top 0
            left 0
            width 245px
            border-bottom 1px solid #ec4692
          span
            &:before
              content ''
              position absolute
              left 0
              top 15px
              width 20px
              height @width
              background url(/images/svg/create_Playlist.svg) 50% 50% no-repeat

      /* config */
      .itemLeft
        float left
        .count
          width 30px
          text-align center
          font-size 12px
          color #000
        .chart
          &_new
            background url("/images/chart_new.png") 50% 45% no-repeat
          &_up
            background url("/images/chart_up.png") 50% 20% no-repeat
          &_down
            background url("/images/chart_down.png") 50% 80% no-repeat
      .itemRight
        float right

      .itemCenter
        display block
        margin 0 auto
        overflow hidden
        > *
          display block
      > * > *
        display inline-block
        line-height $item-height
        vertical-align middle
      h3, .h3
        margin 0
        font-size inherit
        .tablet &
          line-height 60px
      ul
        margin 0
        padding 0

      /* styles */

      .playlistImg
        position relative
        width 60px
        height @width
        overflow hidden
        vertical-align middle
        img
          width 60px
          height @width

      ul.actions
        height $item-height

      li
        display inline-block
        vertical-align middle
        margin 0

      .desc
        padding 0 10px
        font-size 15px
        .artist
        .track
          font-weight bold
        .track
          font-weight normal
        @media (max-width 750px)
          padding 6px 5px
          font-size 15px

        h3, .h3
          white-space nowrap
          overflow hidden
          max-width 100%
          text-overflow ellipsis
          font-weight normal
          a
            color inherit
            //text-decoration none


      .duration
        font-size 12px
        min-width 50px
        text-align center

      &.active
      &:hover
        .play
          position absolute
          background url(/images/svg/play_zvuk.svg) 50% 50% no-repeat
          top 0
          left 0
          height 100%
          width 100%
          &:active
            background url(/images/svg/play_zvuk.svg) 50% 50% no-repeat
      &.load
        .play
          position absolute
          background url(/images/spiner.gif) #E81D7A 50% 50% no-repeat !important
          border-radius 100%
          top 30%
          left 30%
          height 25px
          width 25px

      .like
      .dislike
        width 30px
        height 30px
        margin 0
        &.activated
          background url(/images/svg/like-2.svg) 50% 50% no-repeat
          &:hover
            background url(/images/svg/like-2.svg) 50% 50% no-repeat
      .votes
        color $song-list-active-bg-color
        font-size 12px
        min-width 30px
        padding 0 10px 0 10px
        margin 0 -10px
        &.voteDis
          color red
        &.voteLike
          color #5b5d5f
          .mobile &
            display none

      .like
        background url(/images/svg/like-1.svg) 50% 45% no-repeat
        @media (min-width : 500px)
          &:hover
            background url(/images/svg/like-3.svg) 50% 50% no-repeat

      .dislike
        background url(/images/likes.png) -10px -60px
        &:hover
          background url(/images/likes.png) -60px -60px

      &.active
        background $song-list-active-bg-color
        color $song-list-active-text-color
        .desc
          h3, .h3
            color $song-list-active-text-color

        .votes
          color $song-list-active-text-color

        .like
          background url(/images/svg/like-1.svg) 50% 45% no-repeat
          @media (min-width : 500px)
            &:hover
              background url(/images/svg/like-3.svg) 50% 50% no-repeat
          &.activated
            background url(/images/svg/like-2.svg) 50% 50% no-repeat

        .dislike
          background url(/images/likes.png) -110px -60px
          &:hover
            background url(/images/likes.png) -160px -60px

        .play
          background url(/images/svg/pause_zvuk.svg) 50% 50% no-repeat
          @media (min-width : 500px)
            &:hover
              background url(/images/svg/pause_zvuk_hover.svg) 50% 50% no-repeat

        &.pause
          .play
            background url(/images/svg/play_zvuk.svg) 50% 50% no-repeat
            @media (min-width : 500px)
              &:hover
                background url(/images/svg/play_zvuk_hover.svg) 50% 50% no-repeat
      &:hover
      &.active
        color $link-text-color-hover
        background #F2F2F2
        box-shadow 0 2px 6px rgba(0, 0, 0, 0.201483)
        border-radius 2px
        &.error
          .play
            position relative
            background url(/images/refresh.png) 50% 50% no-repeat !important
      &.active
        color #000
        &:hover
          color #000
  .addedSuccess,
  .addedSuccessCollections
    position fixed
    bottom 68px
    margin-left 230px
    width 100%
    max-width 330px
    text-align center
    padding 10px 30px
    background #e81d7a
    user-select none
    pointer-events none
    opacity 0
    @media (max-width 1030px)
      margin-left 170px
    @media (max-width 500px)
      padding 10px 0
      max-width unset
      left 0
      margin-left 0
      text-overflow ellipsis
      overflow hidden
    @media (max-width 320px)
      padding 10px
      max-width 300px
    .mobile &
      bottom unset
      top 76px
    .tablet &
      bottom 68px
      top unset
    span
      color #fff
      font-weight 500
      font-size 16px
      line-height 20px
      @media (max-width 320px)
        max-width 310px
    &._now
      opacity 1
      z-index 9
      transition all 0.5s linear

.box
  &.widget
    .module-layout
      .item
        display flex
        align-items center
        .itemLeft
          display flex
          align-items center
          float left
          .play
            position initial
            background url(/images/svg/play_zvuk.svg) 50% 50% no-repeat
            width 32px
            height @width
            margin 0 10px
        .itemCenter
          margin 0
          .desc
            padding-left 0
        &:hover
        &.load
          .play
            position initial
            width 32px
            height @width
        &.active
          .play
            background url(/images/svg/pause_zvuk.svg) 50% 50% no-repeat
          &.pause
            .play
              background url(/images/svg/play_zvuk.svg) 50% 50% no-repeat


.mobile
  .item
  .itemSpecial
    h3, .h3
      line-height 25px
    .duration
      display none
    .votes
      display none