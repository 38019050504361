@import "mobile/index"
@import "tablet/index"
//@import "mobile-tablet/index"

body
  &.mobile
    .module-layout, .headlineLine, .topLine
      h1
        line-height 30px
        text-align left
        padding 10px 0
        word-break break-word
      &._search
        h1
          max-width 80%
          overflow hidden
          word-break break-word
      .shared_buttons
        width auto
        #share
         min-width calc(100vw - 20px)
         margin-top 10px
         margin-right 0
         @media (orientation landscape)
           min-width fit-content!important
        .ya-share2__container_size_m
         .ya-share2__list
            display flex
            justify-content space-between
            &.ya-share2__list_direction_horizontal
              margin-top -6px
            .ya-share2__icon
              width 30px
              height @width
              background-size unset
        .support
          height 50px
        .button
          margin-bottom 0
      .box.collectionTop
        .item.main
          h1
            padding 0
        .shared_buttons
          padding 0
          #share
            margin-top 0
    .headlineLine
      .collectionButtonLine
        display none
      h1
        line-height 30px
        padding 0

    .genre-page
      .topLine
        padding-top 10px
        h1
          padding-top 0
      .collectionButtonLine
        padding-left 10px
        .shared_buttons
          padding-top 0
          @media (orientation landscape)
            top 0
          #share
            margin-right 10px
            @media (orientation landscape)
              left 0
    .GenrePage
      .topLine
        .support
          height 30px

  &.iOS
    .player-controls
      .download,
      .mute
        display none !important