.module-layout
  &.playlistPage
    .module-search
      margin-bottom 0
      .mobile &
        margin 20px 10px 0 10px
      #user-playlist-search
        position relative
        align-items center
        input
          color #000
          padding-left 8px
          width 100%
        input::-webkit-search-decoration,
        input::-webkit-search-cancel-button,
        input::-webkit-search-results-button,
        input::-webkit-search-results-decoration
          display none
        .reset
          position absolute
          top 3px
          right 30px
          border none
          display none
          width 18px
          height @width
          background url('/images/reset.png') 50% 50% no-repeat
          transition background 200ms
          outline none
          cursor pointer
        input
          &:valid
            & ~ .reset
              display block
    .buttonsPlaylist
      .shared_buttons.rollUp
        width 36px
        height @width
        img
          margin-bottom 5px
          width 36px
          height @width
        .mobile &
          margin-top 5px
          margin-right 10px
          margin-left auto
          #share
            margin-top 2px
            margin-right -10px
      .button.listen
        &.disabled
          opacity .4
          user-select none
          cursor default
      .mobile &
        display flex
        align-items center
        margin 0 10px
        img
          margin-left 10px
          margin-top -5px
    .searchPlaylist_popup
      padding 0
    .addedTracks
      padding 0
      margin-top 15px
      margin-bottom 0
      @media (max-width 500px)
        padding 0 10px
      &.trackPl
        padding-bottom @margin-top
        @media (max-width 500px)
          padding 0
      li.item
        .songName
          color black
        .inPlaylists
          display none
    .searchPlaylist_popup,
    .addedTracks
      li.item
        display flex
        align-items center
        margin 0
        &:hover
          @media (min-width 750px)
            background #EFEFEF
        .playlistImg
          margin-right 10px
        .play
          position absolute
          left 25px
          top 15px
          width 32px
          height @width
        .songName
          position relative
          overflow hidden
          color black
          flex 1
          div
            line-height 16px
            .artist
              &:after
                content ' — '
                margin 0 5px
              .mobile &
                &:after
                  content ''
            .track
              margin-right 5px
        .inPlaylists
          margin 0 5px
        .duration
          margin 0
        @media (max-width 750px)
          padding 0 10px
          .artist,
          .track
            display inline-flex
            max-height 30px
            overflow hidden
    .addedTracks
      .duration
        display block

  &.favoritePage
    li
      display inline-block
      position relative
      width 100%
      max-width 190px
      padding 0
      margin 0 15px 50px 0
      background none
      text-align left
      font-size 14px
      font-weight bold
      height 210px
      cursor pointer
      @media (max-width 1030px)
        max-width 170px
      @media (max-width 750px)
        margin 0 0 15px 0
      @media (max-width 350px)
        max-width 140px
      a
        width 190px
        height @width
        position relative
        cursor pointer
        display block
        border-radius 4px
        box-shadow 0 5px 10px rgba(0, 0, 0, 0.150136)
        overflow hidden
        margin-bottom 5px
        @media (max-width 1030px)
          width 170px
          height @width
        @media (max-width 350px)
          width 140px
          height @width
      .title
        color #000
        display block
        margin-top 16px
        text-align center
        font-style normal
        font-weight normal
        font-size 14px
        line-height 16px
        max-height 30px
        overflow hidden
        height 100%
        a
          width unset
          height @width
          box-shadow none
          text-decoration unset
      &:hover
        .title
          color #e81d7a
  .newPlaylist
    .newPlaylist_cover
      display flex!important
      align-items center
      justify-content center
      background linear-gradient(89.79deg, #A7F5EB 0.16%, #A6CAF5 99.81%)
      border-radius 4px
      margin-bottom 5px
      width 190px
      height @width
      @media (max-width 1030px)
        width 170px
        height @width
      @media (max-width 350px)
        width 140px
        height @width
      img
        width 70px
        height @width
  .audio_pl_grid
    top 0
    left 0
    width 100%
    height @width
    img
      width 190px
      height @width
      @media (max-width 1030px)
        width 100%
        height @width
    .newPlaylist_item
      background url("/images/album.svg") 50% 50% no-repeat
      @media (max-width 750px)
        width 140px
        height @width
    .audio_pl_grid_cover_once
      width 100%!important
      height @width
    .audio_pl_grid_cover
      position absolute
      background-size cover
      background-position 50%
      width 50%
      height @width
      .mobile &
        width 50%
        height @width
    .audio_pl_grid_cover_1
      left 50%
    .audio_pl_grid_cover_2
      top 50%
    .audio_pl_grid_cover_3
      left 50%
      top @left
  .headerPlaylist
    display flex
    margin-bottom 25px
    .tablet &,
    .mobile &
      margin 0 10px 5px
    .playlist_track
      position relative
      width 190px
      height @width
      @media (max-width 750px)
        width 140px
        height @width
        min-width @width
    .descriptionPlaylist
      margin-left 15px
      @media (max-width 750px)
        margin-left 10px
        width 50%
      .descriptionPlaylist-header
        display flex
        align-items center
        margin-bottom 5px
        .mainTitle
          font-style normal
          font-weight 900
          font-size 12px
          line-height 24px
          letter-spacing 0.1em
          text-transform uppercase
          margin 0
          color #000
          opacity .6
        .edit_playlist-done
        .edit_playlist-err
          display none
        svg
          margin-top -7px
          min-width 24px
          width 24px
          height @width
        path
          stroke-dasharray 99.47578430175781
          stroke-dashoffset -99.47578430175781
          fill transparent
        svg.animate path
          fill #e81d7a
          opacity 1
        @keyframes draw
          0%
            opacity 1
            stroke-dashoffset -99.47578430175781
            fill transparent
            transform translateY(0)
          35%
            stroke-dashoffset 0
            fill transparent
          60%
            fill $palette1
            opacity 1
            transform translateY(0)
          100%
            fill $palette1
            stroke-dashoffset 0
            opacity 0
            transform translateY(-10px)
      .titlePlaylist
        display flex
        font-size 20px
        font-weight bold
        div
          max-width 400px
          border-radius 4px
          border 1px solid transparent
          color #000
          overflow hidden
        span
          font-weight bold
          font-size 20px
          line-height 22px
        .edit_playlist
          margin-left 10px
          margin-right 10px
          width 24px
          height @width
          cursor pointer
          @media (max-width 500px)
            display none
      .buttonsPlaylist
        display flex
        margin-top 18px
        margin-bottom 25px
        .button.listen
          max-width 106px
          padding 0 22px
          line-height 35px
          border-radius 18px
          margin-top 0
          background #e81d7a
          color white
          &:before
            content ''
            display inline-block
            position relative
            left 0
            top 3px
            margin-right 5px
            sprite2Crop(0,6)
            width 16px
            height @width
          &.disabled
            opacity .4
            user-select none
            cursor default
          @media (max-width 750px)
            padding 0 10px
        .remove_playlist
          background url("/images/svg/remove_playlist.svg") 50% 50% no-repeat
          background-color #e81d7a
          width 36px
          height @width
          border-radius 18px
          margin-left 20px
          cursor pointer
          @media (max-width 1030px)
            margin-left 10px
      .bottomPlaylist
        display flex
        justify-content space-between
        .duration
          font-size 14px
          line-height 16px
          color #000
        .emptyTracks
          p
            font-weight 500
            font-size 14px
            line-height 16px
            color #454545
            @media (max-width 1030px)
              line-height 23px
        @media (max-width 350px)
          display block
    .shared_buttons
      margin-left 20px
  #sortable
    margin-top 25px
    padding 0
    p
      color #000
      padding 20px 20px 0
      font-size 16px
      span
        font-size 20px
        color #e81d7a
    &.songs
      padding-bottom 10px
      @media (max-width 1030px)
        padding 0 10px 10px
    .item
      margin-right 0
      margin-left 0
      padding-left 15px
      &:hover
        .handle
          content ''
          position absolute
          left 0
          background url("/images/svg/sortable.svg") 50% 50% no-repeat
          width 15px
          height 100%
      @media (max-width 1030px)
        padding 0
  .modal_removePlaylist
    display none
    position fixed
    z-index 1001
    left 0
    top 0
    width 100%
    height 100%
    overflow auto
    background-color rgba(0,0,0,0.4)
    @media (max-width 500px)
      padding 0
    .modal-content
      background #fff
      box-shadow 0 3px 20px rgba(0, 0, 0, 0.25)
      margin 15% auto
      padding 20px
      border-radius 5px
      height 186px
      width 50%
      max-width 510px
      @media (max-width 1030px)
        width 80%
      @media (max-width 750px)
        padding 0
        margin 40% auto
        height unset
        width 100%
    .close
      float right
      background url("/images/svg/close.svg") 50% 50% no-repeat
      width 20px
      height @width
      cursor pointer
      @media(max-width 500px)
        width 30px
        height @width
    p
      display flex
      flex-direction column
      margin 0
      padding 10px 10px 0
      line-height 40px
      color #222222
      @media (max-width 750px)
        padding 40px 10px 0
        line-height 30px
      span
        &:first-child
          font-weight bold
          font-size 20px
        &:last-child
          font-weight 500
          font-size 16px
    .modal_buttons
      display flex
      justify-content flex-start
      margin 30px 0 30px 10px
      @media (max-width 750px)
        justify-content center
        padding 30px 10px
        margin 0
      @media (max-width 500px)
        justify-content start
      > *
        width 85px
        height 36px
        border-radius 7px
        box-sizing border-box
        font-weight bold
        font-size 14px
        line-height 35px
        text-align center
        cursor pointer
      .button_cancel
        color #e81d7a
        margin-right 30px
      .button_delete
        background #e81d7a
        color #fff