.footer
  padding 5px 20px 10px
  overflow hidden
  .mobile &
    padding 0 10px
  .seo-text
    margin-bottom 62px
    font-weight normal
    font-size 16px
    line-height 18px
    .mobile &
      margin-bottom 29px

  a
    display inline-block
    text-decoration none

  h3, .h3,
  h4,
  h5
    margin-top 0

  p
    margin 0
    color #000

    span
      display inline-block
      font-size 14px
      letter-spacing 1px
      .mobile &
        margin-bottom 13px
      &:last-child
        font-size 16px
      @media (min-width : 500px)
        &:last-child
          float right


.footerContent
  display none
  @media ( min-width 800px )
    display block
    overflow hidden
    padding-bottom 20px
    font-size 14px
    border-bottom 1px solid #777

  .listItems
    li
      vertical-align top
      margin-bottom 2%

  .footerMenu
    float left
    width 32%
    margin 10px 0
    .listItems
      -webkit-column-count 2
      column-count 2

  .footerGenres
    margin-top 10px
    margin-left 32%

    .listItems
      -webkit-column-count 4
      column-count 4
