.module-search
  position relative
  .tablet &
  .mobile &
    margin 0 20px
  form
    outline none
    .inputSearch
      position relative
      display block
      background linear-gradient(270deg, rgba(77, 149, 235, 0.5) 4.86%, rgba(80, 236, 214, 0.5) 100%)
      border-radius 20px
      height 37px
      width 100%
      .mobile &
        &.name
          .dropdownButton
            sprite2Crop(3,2,-7,-8)
        &.artist
          .dropdownButton
            sprite2Crop(4,3,-5,-8)
        &.byRadio
          .dropdownButton
            sprite2Crop(0,3,-4,-10)
        &.collections
          .dropdownButton
            sprite2Crop(8,3,-3,-9)
      .inInputSearch
        overflow hidden
      input
        display block
        width calc(100% - 220px)
        height 37px
        line-height 16px
        font-size 14px
        font-weight normal
        padding 0
        border-radius 20px
        border none
        box-shadow none
        text-indent 16px
        color #000
        background inherit
        outline none
        &::-webkit-input-placeholder {color:#000;}
        &::-moz-placeholder          {color:#000;}/* Firefox 19+ */
        &:-moz-placeholder           {color:#000;}/* Firefox 18- */
        &:-ms-input-placeholder      {color:#000;}
        .mobile &
        .tablet &
          width calc(100% - 30%)
      input::-webkit-search-decoration,
      input::-webkit-search-cancel-button,
      input::-webkit-search-results-button,
      input::-webkit-search-results-decoration
        display none
      @import "../../../../../common/styl/_parts/dropdownmenu.styl"
      .dropdown
        button
          cursor pointer
          height 37px
          position absolute
          right 0
          padding 0
          width 40px
          min-width 40px
          font-size 16px
          line-height 19px
          -webkit-border-top-right-radius 20px
          -webkit-border-bottom-right-radius 20px
          -moz-border-radius-topright 20px
          -moz-border-radius-bottomright 20px
          border-top-right-radius 20px
          border-bottom-right-radius 20px
          border none
          background $linearButtonColor
          color #fff
          font-weight bold
          &:after
            content ""
            display table
            clear both
        .search
          background url(/images/search.svg) 50% 50% no-repeat
          width 40px
          height 37px
          padding 0
          @media (min-width: 516px)
            display block
        select
          color #202020!important
          font-weight bold
          .mobile &
            display none

        .dropdownButton
          top 7px
          right 50px
          background url("/images/svg/dropdownButton.svg") 50% 50% no-repeat
          @media (min-width 1030px)
            transition background linear .5s
          @media (min-width 1030px)
            &:hover,
            &.dropdownButton_active
              background url("/images/svg/dropdownButton_active.svg") 50% 50% no-repeat
        select
          position absolute
          right 84px
          top 0
          color #202020
          font-size 14px
          height 37px
          border none
          outline none
          background transparent
          -webkit-appearance none
          -moz-appearance none
          appearance none
          pointer-events none
      .dropdown-menu
        top 37px
        right 40px
        background-color #e6e6e6
        box-shadow none
        z-index 2
        .name
          &:before
            sprite2Crop(3,5,-4,-11)
          &:hover:before
            sprite2Crop(3,2,-4,-8)
        &.name
          li.name
            color #e81d7a
            &:before
              sprite2Crop(3,2,-4,-8)
        .artist
          &:before
            sprite2Crop(4,5,-5,-9)
          &:hover:before
            sprite2Crop(4,3,-5,-8)
        &.artist
          li.artist
            color #e81d7a
            &:before
              sprite2Crop(4,3,-5,-8)
        .byRadio
          &:before
            sprite2Crop(2,5,-5,-9)
          &:hover:before
            sprite2Crop(0,3,-5,-9)
        &.byRadio
          li.byRadio
            color #e81d7a
            &:before
              sprite2Crop(0,3,-5,-9)
        .collections
          &:before
            sprite2Crop(8,5,-3,-9)
          &:hover:before
            sprite2Crop(8,3,-3,-9)
        &.collections
          li.collections
            color #e81d7a
            &:before
              sprite2Crop(8,3,-3,-9)
        li
          color #545454
          &:hover
            color #e81d7a
            opacity .9