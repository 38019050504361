ul.top-menu-box
  padding 0
  margin 0 5px 0 0
  white-space nowrap
  line-height 60px
  vertical-align middle
  li
    display inline-block
    a
      overflow hidden
      color #fff
      display inline-block
      line-height 60px - 4px
      text-decoration none
      margin 0 0 0 15px
      height 100%
      border-bottom 4px solid transparent
      &:hover
        border-bottom 4px solid #cceef7
    &.activate
      a
        border-bottom 4px solid #cceef7

.mobile
.tablet
  ul.top-menu-box
    display none
