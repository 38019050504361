ul.menu-box
  padding 0 20px
  vertical-align middle
  li
    display block
    margin 23px 0
    &:last-child
      margin-bottom 37px
    &.activate
      a
        color $link-text-color-hover
    a
      overflow hidden
      color $link-text-color
      font-style normal
      font-weight bold
      font-size 16px
      line-height 18px
      display block
      text-decoration none
      height 100%
      &:hover
        color $link-text-color-hover

.tablet
  .container
    .content
      margin-top 0
  .mobileShow
    .box
      padding 0
      ul.menu-box
        text-align center
        margin 0 0 10px
        li
          display inline-block
          margin 10px 5px
          a
            color $link-text-color
            margin 0!important
            padding-left 0
            border-left none
            background transparent
            border-bottom transparent
            &:hover
              border-bottom transparent
          &.activate
            border-bottom 1px solid $link-text-color-hover

.mobile
  .container
    .content
      margin-top 20px
  .box
    padding 0
  ul.menu-box
    padding 0
    max-height calc(100vh - 135px)
    overflow-y scroll
    overflow-x hidden
    li
      display flex
      margin 0
      width 100%
      text-align start
      img
        align-self center
        margin-left 11px
        width 16px
      a
        display block
        padding 10px
        width 100%
        background transparent
        border-bottom transparent
        color #000
        font-size inherit
        font-weight 700
        margin 0
      &.activate
        background #e7ebef
        a
          color $seekbar-played-bg-color

  #mobileMenuShow
    background #fff
    width 100%
    box-shadow 0 5px 6px 0 #e7ebef
    display none
  #hamburger-icon
    width 37px
    height 35px
    position absolute
    display block
    top 22px
    left 10px
    -webkit-border-radius 3px
    -moz-border-radius 3px
    border-radius 3px
    z-index 11

    .line
      display block
      background #ecf0f1
      width 24px
      height 3px
      border-radius 1px
      position absolute
      left 9px
      margin-top 9px
      transition all 0.4s
      -webkit-transition all 0.4s
      -moz-transition all 0.4s
      &.line-1
        top 0
      &.line-2
        top 20%
      &.line-3
        top 40%

    &.active
      .line-1
        transform translateY(7px) translateX(0) rotate(45deg)
        -webkit-transform translateY(7px) translateX(0) rotate(45deg)
        -moz-transform translateY(7px) translateX(0) rotate(45deg)
      .line-2
        opacity 0
      .line-3
        transform translateY(-7px) translateX(0) rotate(45deg * -1)
        -webkit-transform translateY(-7px) translateX(0) rotate(45deg * -1)
        -moz-transform translateY(-7px) translateX(0) rotate(45deg * -1)

#mobileMenuShow.down
  display block
  z-index 999