.myMusic
  float right
  margin-top -2px
  color white
  margin-right 5px
  cursor pointer
  .mobile &
    margin-left 20px
    margin-right 15px
  span
    display inline-block
    vertical-align middle
    font-size 18px
    font-weight bold
    color #fff
  img
    width 34px
    height @width
    object-fit cover
    margin 0 5px 0 15px
    display inline-block
    vertical-align middle
    border-radius 9999px
    .mobile &
      margin 0

.dropdownUserMenu
  position absolute
  background-color #fff
  width 117px
  right 0
  top 60px
  font-size 14px
  text-align left
  border none
  border-radius 5px
  display none
  z-index 3
  box-shadow 0 4px 20px rgba(0, 0, 0, 0.25)
  span
    font-weight 500
    font-size 14px
    line-height 30px
    width 80%
    padding-left 21px
    color #000
    a
      display block
      color #000
      &:active
        color #000

#show_popup_auth
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 9999
  background #0008
  display none
  .openModal &
    display block

  $padding = 25px
  .container
    position relative
    width 100%
    box-sizing border-box
    top 50px
    max-width 375px
    padding 40px 40px 25px 36px
    background white
    border-radius 5px
    font-size 18px
    font-weight 700
    .title,
    .title__buttons,
    .title__last-login
      color #e81d7a
      font-style normal
      font-weight 500
      font-size 20px
      line-height 23px
      min-width 300px
      margin-bottom 15px
    .title__buttons,
    .title__last-login
      font-size 16px
      line-height 19px
      margin-bottom 10px
    .title__buttons
      margin-top 30px

  .close
    position absolute
    top 20px
    right @top
    cursor pointer
    padding 10px
    margin - (@padding)

  .authButton
    display block
    position relative
    height 50px
    width 300px
    line-height @height
    border-radius 5px
    margin-bottom 20px
    color white
    font-size 16px
    font-weight 300
    background lightgray//fallback
    &:hover
      opacity 0.9
    &:active
      box-shadow inset 0 0 3px 0 #000
      text-decoration none
    &:after
      content ''
      display block
      position absolute
      top 0
      left @height
      height 100%
      width 1px
      background #0003
      box-shadow 1px 1px 0 0 #fff2
    img
      margin 8px
      margin-right (@margin + 15px)
      width (@height - 2 * @margin)
      height (@height - 2 * @margin)
      float left
    &.vkauth
      background #39739b
    &.mailauth
      background #005ff9
    &.okauth
      background #ec7f21
    &.yandexauth
      background #DB0D0D

  .mobile &
    background none
    .container
      position absolute
      top 0
      bottom 0
      left 0
      right 0
      border-radius 0
      max-width unset
      padding 16px
      overflow scroll
      @media (orientation: landscape)
        max-height 100vh
        max-width 100%
      .title
        max-width 90%
      .auth_info
        width 300px
    .authButton
      width unset
      font-size 14px