generateRulesWidget(props)
  props = arguments if length(arguments) > 1
  for prop, index in props
    for nestprop in prop
      div.CategoryImage.idx{nestprop}
        background-position-x unit(floor(index%4)*-113,px)
        background-position-y unit(floor(index/4)*-113,px)

generateRules(props)
  props = arguments if length(arguments) > 1
  for prop, index in props
    for nestprop in prop
      div.CategoryImage.idx{nestprop}
        background-position-x unit(floor(index%4)*-190,px)
        background-position-y unit(floor(index/4)*-190,px)
//                    1      2   3   4     5     6     7     8      9     10    11    12    13    14  15  16     17     18    19    20    21    22
iconToGenreIdMap = shanson, rap, hip-hop, rnb, techno, instrumental, trance, metal, classical, blues, jazz, trap, reggae, alternative, ost, pop-and-disco, rock, house, other, , sport, holidays, children, soviet-song, v-mashinu, seasons, radio, chillout, nationals, dj-remix, games, italo-disco, klubnye-dance, ringtones, na-sms, bards, french-musical, festivals, other, standup, anime

ul.genres-box
  display flex
  padding 0
  font-size 0
  justify-content space-between
  flex-flow row wrap
  .emptyGenre
    width 190px
    height 0
    opacity 0
    .tablet &
    .mobile &
      width 110px
  .GenreSidebar2 &
    margin 0
  .CategoryWrapper
    display inline-block
    vertical-align top
    margin 0 0 30px
    width 190px
    &:nth-last-of-type(-n+2)
      margin 0 0 10px
    &:hover
    &.active
      span
        color #e81d7a
    @media (min-width 1030px)
      img
        width 190px
        height @width
    span
      display inline-block
      width 190px
      line-height 18px
      color #000
      font-size 14px
      font-weight 500
      margin-bottom 2px
      margin-top 17px
      text-align center
      text-align-last center
      .mobile &
        width 110px
    .mobile &
      width 110px
      span
        width 110px
  .GenreSidebar2 &
    .CategoryWrapper
      width 130px
      span
        width 130px
      .CategoryImage
        width 130px
    .CategoryImage_background
      display flex
      align-items center
      justify-content center
      width 130px
      height @width
      img
        width 130px
        height @width

  @media (max-width 400px)
    columns 2
    justify-content space-around

.desktop-sidebar
  ul.genres-box
    justify-content space-between
    li
      display block
      width auto

.module-layout.GenrePage
  .topLine
    .topLineName
      margin-left 0
  .collectionButtonLine
    .mobile &
      margin 10px 0
      .shared_buttons
        padding 0
  h3, .h3
    text-align start
    margin 10px
    .tablet &
    .mobile &
      text-align center

.module-layout.genre-page
  .header-genre-page
    display flex
    position relative
    box-sizing border-box
    padding 20px
    margin 20px 0
    width 100%
    z-index 1
    .left
      margin-left 20px
      z-index 10
      .shared_buttons
        padding 0
        #share
          margin-top 0
      .tablet &
        margin-left 10px
        .item.main
          margin 0 0 15px
      h1
        margin 0
        text-align start
        font-style normal
        font-weight 700
        font-size 30px
        line-height 40px
        color #fff
    .right
      position absolute
      right 20px
      bottom 20px
      z-index 10
    img
      min-width 200px
      width 200px
      height @width
      border-radius 4px
      z-index 10
    .plug
      position absolute
      box-sizing border-box
      overflow hidden
      top 0
      left 0
      width 100%
      height 100%
      z-index 0
      &:after
        content ''
        position absolute
        top 0
        left 0
        width 100%
        height @width
        background-color #000
      img
        position absolute
        object-fit cover
        margin -10px
        filter blur(10px)
        mix-blend-mode screen
        opacity 0.5
        width 110%
        height @width
        z-index 10

.mobile
  .module-layout.genre-page
    .header-genre-page
      padding 10px
      margin 10px 0
      .left
        width 100%
        h1
          font-size 20px
          line-height 20px
          margin-bottom 5px
          min-height 60px
          padding 0
      .item.main
        display inline-block
        .button
          margin-bottom 0
      .shared_buttons.rollUp.smallRollup
        padding 0
        #share
          position absolute
    .header-genre-page>img
      min-width 98px
      width 98px
      height @width