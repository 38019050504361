sprite2Crop(i,j,iFix=0,jFix=0)
  background url(/images/sprite2.svg) (-42px*i)+(iFix)px (-42*j)+(jFix)px no-repeat

.player-controls
  background $fixed-bottom-bg
  box-shadow 0 20px 20px rgba(0, 0, 0, 0.100119)
  border-radius 5px
  max-width 1180px
  margin 0 auto
  padding 8px 0 0
  height 60px
  -webkit-user-select none
  -moz-user-select none
  -ms-user-select none
  user-select none

  .group
    &.basic
      width 259px
      font-size 1px
      line-height 0
      .tablet &
        margin-right 30px
      .mobile &
        width 86%
        @media (orientation : landscape)
          width 93%
      .control
        justified()
        width 102px
    &.basic,
    &.additional
      justified()
      line-height 0
      > *
        cursor pointer
    &.additional
      line-height 20px
      margin-top 20px
      @media (max-width 800px)
        display none

      //@import "responsive"

  .control
    .prev
      sprite2Crop(0,0,-4,-7)
      width 20px
      height 20px
      cursor pointer
      :not(.mobile)
      :not(.tablet)
        &:active
          opacity 0.5
        &:hover
          sprite2Crop(0,1,-4,-7)
          filter unquote('alpha(opacity=50)')

    .next
      sprite2Crop(3,0,-4,-7)
      width 20px
      height 20px
      cursor pointer
      :not(.mobile)
      :not(.tablet)
        &:active
          opacity 0.5
        &:hover
          sprite2Crop(3,1,-4,-7)
          filter unquote('alpha(opacity=50)')

    .play,
    .pause
      width 24px
      height 24px

    .play,
    .pause,
    .download
      cursor pointer

    .play
      sprite2Crop(1,0,-9,-7)

      .playing&
        display none
      :not(.mobile)
      :not(.tablet)
        &:hover
          sprite2Crop(1,1,-9,-7)

    .pause
      sprite2Crop(2,0,-8,-8)

      .playing&
        inline-block()
      :not(.mobile)
      :not(.tablet)
        &:hover
          sprite2Crop(2,1,-8,-8)

  .track-name
    font-size 15px
    font-weight 600
    display block
    width 490px
    margin-right 0
    overflow hidden
    height 40px
    line-height 36px
    text-overflow ellipsis
    white-space nowrap
    color $header-track-text-color
    vertical-align middle
    .mobile &
      display none
      &.mobile
        display block
        overflow hidden
        width 93%
        margin 8px 10px 0 10px
        h3, .h3
          display inline-flex
          white-space nowrap
          vertical-align middle
          line-height 18px
          margin 0
          width 80%
          flex-direction column
          font-size 15px
          font-weight 600
          max-width 100%
          span
            text-overflow ellipsis
            overflow hidden

    .inited&
      opacity 0
      filter unquote('alpha(opacity=0)')

    .playerImg
      font-size 0
      line-height 0
      display inline-block
      vertical-align middle
      margin-right 10px
      background #fff
      img
        width 40px
        height 40px
        border-radius 2px
        box-sizing border-box
        box-shadow 0 5px 10px rgba(0, 0, 0, 0.150136)

  .duration
    font-size 13px
    font-weight normal
    width 70px
    .mobile &
      display none

  .mute
    display block
    cursor pointer
    height 16px
    width 21px
    sprite2Crop(8,0,-5,-6)
    &:hover
      sprite2Crop(8,2,-5,-6)
    &.active
      sprite2Crop(8,2,-5,-6)
      + .volume-bar .volume-bar-value
        width 0%!important

  .volume-bar
    vertical-align top
    margin-top 7px
    cursor pointer
    padding 0
    overflow hidden
    display inline-block !important
    width 140px
    height 4px
    border-radius 20px
    background $volumebar-bg-color

    .volume-bar-value
      cursor pointer
      border-radius 20px
      background $volumebar-active-bg-color
      width 90%
      height 4px
      transition width 80ms linear,height 80ms linear

  .repeat
    cursor pointer
    width 20px
    height 20px
    sprite2Crop(4,0,-4,-7)
    &.active
      sprite2Crop(4,1,-4,-7)
    :not(.mobile)
    :not(.tablet)
      &:hover
        sprite2Crop(4,1,-4,-7)

  .shuffle
    cursor pointer
    width 20px
    height 20px
    sprite2Crop(5,0,-4,-7)
    &.active
      sprite2Crop(5,1,-4,-7)
    :not(.mobile)
    :not(.tablet)
      &:hover
        sprite2Crop(5,1,-4,-7)

.progress
  position relative
  top 0
  max-width 1180px
  margin -8px 10px 0 10px
  height 9px
  .seek-bar
    overflow hidden
    transition height linear 0.2s
    top 0
    height 8px
    border-radius 20px
    background $seekbar-bg-color
    .play-bar
      position absolute
      top 1px
      z-index 2
      inline-block()
      border-radius 20px
      height 8px
      background $seekbar-played-bg-color
    .load-bar
      position absolute
      top 1px
      left 0
      z-index 1
      inline-block()
      height 4px
      background $seekbar-load-bg-color

  .handler
    width 12px
    height 12px
    position absolute
    top -2px
    left 0
    border-radius 50%
    background $seekbar-handler-bg-color
    box-shadow 0 5px 10px rgba(0, 0, 0, 0.150136)
    border 1px solid rgba(0, 0, 0, 0.1006)
    margin-left -8px
    transition margin-top linear 0.2s
    z-index 3

.download
  .dl
    display block
    width 31px
    height 31px
    background url(/images/svg/dl_zvuk.svg) 50% 50% no-repeat

.mobile
  .player-controls
    .volume-bar
      display none !important
    .mute
      display none

@media only screen and (max-width : 400px) and (orientation: portrait)
  .player-controls
    .volume-bar
      display none !important
    .mute
      display none