.dropdown
  .search
    display block
    background url(/images/search.png) 50% 50% no-repeat
    background-size 60%
    background-color $MainColor3
    float right
    width 30px
    height 31px
    padding 3px
    border none
    @media (min-width : 516px)
      display none
  .dropdownButton
    position absolute
    width 25px
    height 25px
    background url('/images/dropdown.svg') 50% 50% no-repeat content-box
    color #474747
    right 98px
    top 7px
    z-index 9
    cursor pointer
    -webkit-appearance none
    -moz-appearance none
    appearance none
    @media ( max-width 500px )
      right 45px
  button
    position absolute
    right 0
    top 0
    cursor pointer
    height $searchHeight
    padding 0
    width 91px
    font-size 16px
    font-weight normal
    line-height 36px
    -webkit-border-top-right-radius 5px
    -webkit-border-bottom-right-radius 5px
    -moz-border-radius-topright 5px
    -moz-border-radius-bottomrigh 5px
    border-top-right-radius 5px
    border-bottom-right-radius 5px
    background $MainColor3
    color #ffffff
    box-sizing initial
    z-index 9
  .select
    cursor pointer
    display block
    padding 12px
.dropdown,
.dropdown-menu
  &.name_focus
    li.name
      color $MainColor3
      &:before
        background url('/images/byName_active.svg') 50% 50% no-repeat content-box
  &.artist_focus
    li.artist
      color $MainColor3
      &:before
        background url('/images/byArtist_active.svg') 50% 50% no-repeat content-box
  &.collections_focus
    li.collections
      color $MainColor3
      &:before
        background url('/images/byCollections_active.svg') 50% 50% no-repeat no-repeat content-box
  &.byRadio_focus
    li.byRadio
      color $MainColor3
      &:before
        background url('/images/byRadio_active.svg') 50% 50% no-repeat no-repeat content-box

.dropdown-menu
  position absolute
  background-color #fff
  width 164px
  right 0
  top 30px
  font-size 13px
  text-align left
  border none
  border-radius 5px
  display none
  z-index -1
  box-shadow 0 5px 4px -1px #5a5a5a
  li:hover
    color $MainColor3
    opacity .7
  @media (max-width : 1000px)
    right 0

  li
    padding 3px
    //transition all .2s ease-in-out
    cursor pointer
    line-height 20px
    color #474747
    &:first-child
      padding-top 10px
  .name
    &:before
      content ''
      position relative
      display inline-block
      background url('/images/byName.svg') 50% 50% no-repeat content-box
      width 30px
      height 20px
      top 4px
    &:hover:before
      background url('/images/byName_active.svg') 50% 50% no-repeat content-box
  .artist
    &:before
      content ''
      position relative
      display inline-block
      background url('/images/byArtist.svg') 50% 50% no-repeat content-box
      width 30px
      height 20px
      top 4px
    &:hover:before
      background url('/images/byArtist_active.svg') 50% 50% no-repeat content-box
  .collections
    &:before
      content ''
      position relative
      display inline-block
      background url('/images/byCollections.svg') 50% 50% no-repeat content-box
      width 30px
      height 20px
      margin -1px auto
      top 4px
    &:hover:before
      background url('/images/byCollections_active.svg') 50% 50% no-repeat content-box
  .byRadio
    &:before
      content ''
      position relative
      display inline-block
      background url('/images/byRadio.svg') 50% 50% no-repeat content-box
      width 30px
      height 20px
      margin -1px auto
      top 4px
    &:hover:before
      background url('/images/byRadio_active.svg') 50% 50% no-repeat content-box
  span
    width 100%
    margin-left 5px
    &.active_span
      color $MainColor3