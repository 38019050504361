.filters
  .headlineLine
    .shared_buttons
      float right
      width 215px
      margin 6px 0 0
.topLine
  display flex
  justify-content space-between
  align-items center
  margin-top 15px
  margin-bottom 25px
  .mobile &
    margin-bottom 0
  &.favoritePage
    display flex
    position relative
    flex-direction column
    align-items start
    margin-top 15px
    margin-bottom 7px
  @media (max-width 750px)
    align-items unset
  h1
    line-height 30px
  .logout
    position absolute
    right 0
    bottom 25px
    a
      display flex
      align-items center
      height 20px
    span
      font-weight bold
      font-size 16px
      line-height 24px
      color #292929

.headlineLine
  margin-top 25px
  margin-bottom 22px
  &.thisCollections
    margin-bottom 0
  &.notCollections
    .mobile &
      display flex
      width 100%
      align-items center

.module-layout
  @media (max-width 1030px)
    .topLine
      padding 0 10px
  @media (max-width 750px)
    &.genre-page
      .topLine
        display flex
        align-items center
    &.GenrePage
      .topLine
        display flex
        .shared_buttons
          padding 10px 0
          #share
            margin-top 0
  .box
    .right
      display flex
      justify-content space-between
      .shared_buttons
        display flex
        align-items flex-start
        padding 10px 0
        min-width max-content

.shared_buttons
  display flex
  position relative
  align-items center
  justify-content flex-end
  #share
    min-width 192px
  @media (max-width 750px)
    padding 10px 0
  p
    margin-right 10px
  .show_share
    color #fff
    text-align center
    line-height 23px
    display none
    width 106px
    &.active
      background-color $palette1
      color #fff
      margin-bottom 13px
    .mobile &
      width 36px!important
      height @width
      min-width @width
  &.rollUp
    display block
    float right
    cursor pointer
    p
      display none
    #share
      position absolute
      right -85px
      top 50px
      border-radius 3px
      background #fff
      box-shadow 0 4px 20px rgba(0, 0, 0, 0.25)
      padding 7px
      display none
      max-width 310px
      white-space nowrap
      .mobile &
        right -7px
        padding 11px 7px 7px
        z-index 999
      &:before
        content ''
        position absolute
        bottom 65%
        left 50%
        width 20px
        height 20px
        background-color #fff
        border-radius 4px
        border-width 9px
        margin-left -9px
        transform rotate(45deg)
        .mobile &
          right 15px
          left unset
    .show_share
      display block

  .support
    display none!important
    height 32px
    margin-bottom 0