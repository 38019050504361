.delimiter
  text-align center
  margin-top 1em
  margin-bottom 1em
  overflow hidden
  height 32px
  line-height 30px
  .pagination
    padding 0 20px
    .mobile &
      padding 0
    ul
      position relative
      display inline-block
      &:before
      &:after
        content ""
        display inline-block
        width 999px
        position absolute
        bottom 45%
        border-bottom 1px dashed #dcdcdc
        .mobile &
          display none
      &:before
        right 105%
      &:after
        left 105%
      .dots
        color black
        margin 0 12px
      a
        height 100%
        inline-block()
        text-decoration none
        font-style normal
        font-weight bold
        font-size 12px
        color #000
        li
          margin-right 2px
          width 30px
          height 30px
          &.activePage,
          &:hover
            border-radius(50%)
            background #e81d7a
            color #fff

    .paginationArrow
      font-style normal
      font-weight 500
      font-size 24px
      line-height 16px
      color #000