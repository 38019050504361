@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';

.swiper-slide
  width unset
  & + &
    margin-left 20px
  .mobile & .cover
    width  170px
    height 170px
  .cover
    width  190px
    height 190px
  img
    width 100%
    height 100%
.swiper-button-next, .swiper-button-prev
  width 50px
  height 50px
  top 26%
  background #fff
  color #e81d7a
  text-align center
  font-size 15px
  line-height 30px
  border-radius 100%
  box-shadow 0 3px 5px rgba(0,0,0,0.3)
  --swiper-navigation-size: 16px
  &.swiper-button-disabled
    display none
  &:hover
    background #efeded