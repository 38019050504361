@import 'settings.styl'

inline-block(valign = initial)
  display inline-block
  if (valign != initial)
    vertical-align valign
* html &,
*+html &
  display inline

border-radius(r1, r2 = r1)
  border-radius r1 r2
  -moz-border-radius r1 r2
  -webkit-border-radius r1 r2


placeholder-color(color)
  &:-moz-placeholder
    color color
  &::-moz-placeholder
    color color
  &:-ms-input-placeholder
    color color
  &::-webkit-input-placeholder
    color color

horizontal-centered(m-top = 0, m-bottom = 0)
  margin m-top auto m-bottom auto


justified()
  text-align justify

  > *
    inline-block()

  &:after
    content ' '
    inline-block()
    width 100%
    font-size 0
    line-height 0

inverse-underline()
  text-decoration none

  &:hover
    text-decoration underline

middle(height = 24px, $input = false)
  border-width 1px
  border-style solid
  round-btn(height, 1px, 0 ,$input)

background-linear-gradient-image(url, startColor, endColor, startPoint = top, startInterval = 0%, endInterval = 100%)
  background-color startColor
  background-image url, -webkit-linear-gradient(startPoint, startColor startInterval, endColor endInterval)
  background-image url, -moz-linear-gradient(startPoint, startColor startInterval, endColor endInterval)
  background-image url, -ms-linear-gradient(startPoint, startColor startInterval, endColor endInterval)
  background-image url, -o-linear-gradient(startPoint, startColor startInterval, endColor endInterval)
  background-image url, linear-gradient(startPoint, startColor startInterval, endColor endInterval)

roundy(radius = 3px)
  border-radius(radius)

round-btn($height, $border = 1px, $b_radius = 0,$input = false)
  if $b_radius != 0
    $radius = $b_radius
  else
    $radius = $height

  $inner-height = $height - ($border*2) - ($height*0.65)

  height $inner-height
  line-height $inner-height

  if $input
    height $height
    line-height ($inner-height*1.1)

  if $b_radius == 0
    padding ($height*0.35) ($radius/2) ($height*0.3) ($radius/2)
  else
    padding ($height*0.3) (($height - $border)/2) ($height*0.3) (($height - $border)/2)

  border-radius($radius)

underline($padding, $border-color, $hover = true)
  text-decoration none !important
  padding-bottom $padding
  line-height normal
  border-bottom 1px solid $border-color
  transition border-color $transition-delay ease

  if $hover
    &:hover
      transition  border-bottom ease
      border-color transparent

user-selection()
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */

  /* No support for these yet, use at own risk */
  -o-user-select: none;
  user-select: none;

rotate-el(deg)
  -webkit-transform: rotate(deg);
  -moz-transform: rotate(deg);
  -o-transform: rotate(deg);
  writing-mode: tb-rl;

sprite2Crop(i,j,iFix=0,jFix=0)
  background url(/images/sprite2.svg) (-42px*i)+(iFix)px (-42*j)+(jFix)px no-repeat