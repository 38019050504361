@import "logo"
@import "../player/index"
@import "../../../../../common/styl/ad/banner.styl"

.header
  z-index 9999
  position sticky
  width 100%
  height $header-height
  top 0
  background url(/images/background.jpg) no-repeat, linear-gradient(to right, #4d95eb, #52a0fb, #30ab98)
  background-size cover
  background-position 60%

  .mobile &
    height $header-height-mobile
    background linear-gradient(270deg, #23AE78 0%, #53A0FD 47.52%, #4A90E2 100%)

  .container
    background transparent
    overflow visible

  .inHeader
    overflow hidden

    .inHeaderRight
      float right
      display inline-block
      overflow hidden

  .container
    height 100%
    margin 0 auto
    padding 0