@charset "utf-8"

@import "../reset"
@import "../settings"
@import "../mixins"
@import "../header/index"
@import "../footer/index"
//@import "../popular/index"
@import "../song-items/index"
@import "../pagination"
@import "../responsive/index"
//@import "../modal"
@import "../search/index"
@import "../radio/index"
@import "../auth/index"
//@import "../popup/index"
//@import "../ad/index"
@import "../sharedButtons"
@import "../swiper.styl"

if $specific-search-styl
  @import $specific-search-styl
else
  @import "../search/index"

@import "../menu/index"
@import "../top-menu/index"

@import "../plate/index"

// new
@import "../newmixins"
//@import "../ad"
@import "../buttons-box/index"
@import "../genres/index"
@import "../filters"

@import "../playlist"

//Banners
#sveta__muzika-banner
  background url("/images/promo/banners_desc/sveta__muzika-banner.png") no-repeat, linear-gradient(to right, #131113, #220f12)
  background-position center
  width 100%
  height 100px
  @media (max-width 1040px)
    background url("/images/promo/banners_desc/sveta__muzika-banner-tablet.png") no-repeat, linear-gradient(to right, #360c25, #2b4101)
    background-position center
  @media (max-width 680px)
    background url("/images/promo/banners_mobile/sveta__muzika-banner-mobile.jpg") no-repeat, linear-gradient(to right, #360c25, #2b4101)
    background-position center
    height 170px
  @media (max-width 375px)
    height 150px
    background-size cover
  @media (max-width 350px)
    background url("/images/promo/banners_mobile/sveta__muzika-banner-mobile-sm.jpg") no-repeat, linear-gradient(to right, #360c25, #2b4101)
    background-position center
    height 170px

button, select
  outline none

.hide
  display none !important

html
  height: 100%;
  background $background-color
  font-family $font-family

body
  font-size $font-size
  line-height $line-height
  color $base-text-color

a
  color $link-text-color
  text-decoration none
  &:hover
    color $link-text-color-hover
    text-decoration underline

.container
  max-width $site-width
  min-width 320px
  padding-bottom 65px
  margin 0 auto
  //overflow hidden
  position relative
  background $container-bg-color


  .content
    z-index 1
    margin-top 25px
    //overflow hidden
    margin-bottom 25px
    @media (min-width 801px)
      display grid
      gap 35px
      grid-template-columns 281px 1fr
    @media (max-width 500px)
      overflow unset

    .inHeader
      margin-bottom 10px

    span,
    .mobile
      .box
        padding 0 10px
        overflow visible

    .desktop
      background $content-bg-color
      overflow hidden


    .desktop-sidebar
      //float left
      //width $sidebar-width
      //margin-right 35px
      padding 1px

      //@import "../../../../../common/styl/_parts/youtubewidget.styl"

      .mobileShow
        margin-bottom 33px

      @media only screen and (max-width : 800px)
        width 100%
        float none
        margin-right 0
        > *
          display none
        > .mobileShow
          display block !important
        ul.menu-box
          text-align center
          margin 0
          li
            display inline-block
            a
              color $link-text-color
              margin 3px 5px
              &:hover
                border-bottom transparent

.mobile
.tablet
  .container
    .content
      .desktop-sidebar
        width 100%
        float none
        margin-right 0
        > *
          display none
        > .mobileShow
          display block !important
        ul.menu-box
          text-align center
          margin 0
          li
            display inline-block
            a
              color $link-text-color
              margin 3px 5px
              &:hover
                border-bottom transparent

.fixedBottom
  position fixed
  bottom 0
  left 0
  width 100%
  z-index 1000
  .container
    display flex
    align-items center
    justify-content space-between
    margin 0 10px 0 20px
    max-width unset
    height 100%
    background transparent
    .mobile &
      margin 0 25px
      width 100%
      height 57px
    @media (max-width 350px)
      margin 0 20px


.boxShadow
  background #ffffff
  box-shadow 0 2px 6px rgba(0, 0, 0, 0.201483)
  border-radius 2px
  margin-bottom 10px

.box
  overflow hidden
  padding $box-padding
  margin-bottom $box-margin-bottom
  &.filters
    overflow visible
    margin-bottom 10px
  &.collections
    margin 0
  h3, .h3
    color $collection-title-color
    margin $box-h-margin
    font-size $box-h-font-size
    font-weight $box-h-font-weight
    .desktop-sidebar &
      margin 14px 0 21px
  &.widget
    margin-bottom 45px
    .module-layout
      margin-bottom 12px
      .item
        padding 0
  .mobile &
    &.widget
      margin 0 10px 40px 10px
      h3, .h3
        text-align center
        margin-bottom 25px
        font-size 24px

.taLeft
  text-align left

.taRight
  text-align right

.taCenter
  text-align center
  height 40px
  width 280px
  background rgba(0, 0, 0, 0.1)
  border-radius 25px
  text-decoration none
  border-bottom none
  line-height 40px
  &:hover
    color $link-text-color-hover
  .mobile &
    width 100%

.moreButton
  display inline
  text-decoration none
  color #000
  font-size 16px
  font-style normal
  font-weight normal
  &:hover
    text-decoration none

.mobile
  .mobileHide
    display none !important

.mobileHide
  margin-bottom 27px
  &._plug
    p
      color white
  p
    color #000
p
  margin 10px 0
  font-size 14px

.empty-block
  width 160px
  .tablet &
    display none
  .mobile &
  .mobile &
    width 20%

h1
  margin 0
  font-style normal
  font-weight bold
  font-size 24px
  line-height 60px
  color #000
  &:first-letter
    text-transform uppercase
  .mobile &
    text-align center
    font-size 23px

.button
  background #E81D7A
  color #fff
  display inline-block
  margin-top 13px
  padding 8px 19px
  vertical-align middle
  text-decoration none
  border-radius 25px
  cursor pointer
  .mobile &
    margin-top 0
    margin-bottom 5px
    padding 7px 10px
  .tablet &
    margin-bottom 0
  &.anchor
    margin-left 5px
    .mobile &
      margin-top 5px
  &.show_share
    background #FFFFFF
    border 2px solid #E81D7A
    padding 3px 10px
    color #E81D7A
    &:hover
      color #E81D7A
  &.muTorrent
    padding-left 30px
    position relative
    &:after
      position absolute
      content ''
      display block
      height 22px
      width 22px
      background url(/images/mu.png)
      background-size contain
      top 2px
      left 5px
  &:hover
    text-decoration none
    color #fff
    opacity 1
  &.favoriteAdded
    background #00aeef
  &.addFavorite
    font-size 26px
    font-weight bold
    padding 2px 5px
  &.outline
    background inherit
    color black
    .mobile &
      text-align center
  &.outline.activated
    background url("/images/svg/remove_playlist.svg") 50% 50% no-repeat
    background-color #e81d7a
  &.favorite
    background url("/images/svg/add_in_my_music.svg") 50% 50% no-repeat
    background-color #e81d7a
    width 36px
    height @width
    margin-left 5px
    border-radius 18px
    padding 0
.listen
  &:before
    content ''
    display inline-block
    position relative
    width 16px
    height 15px
    margin-right 6px
    top 3px
    background url("/images/svg/buttonListenPlay.svg") 50% 50% no-repeat
  &.playing:before
    background url("/images/svg/buttonListenPause.svg") 50% 50% no-repeat

.button_tags
  background linear-gradient(89.79deg, #A7F5EB 0.16%, #A6CAF5 99.81%)
  color #000
  font-size 12px
  line-height 20px
  display inline-block
  margin-top 13px
  margin-right 6px
  padding 3px 15px
  vertical-align middle
  text-decoration none
  border-radius 20px
  cursor pointer
  &:hover
    color #000
    text-decoration none


.linkColor
  color $link-text-color-hover

.topAvatar
  vertical-align top
  img
    margin-top 6px
    border-radius 25px

/*.ajaxContentLoaded
  &:after
    content ''
    display block
    width 20px
    height 20px
    margin auto
    background  url('/images/spiner.gif') #E81D7A 50% 50% no-repeat
    border-radius 25px*/


.smallMargin
  .box
    margin 0

.emptyTracks
  p
    display none
    margin 0
  .mobile &
    margin 20px 10px

// branding
//body.branding
//  height auto !important
//  position relative !important
//  &:not(.vk-download)
//    .skin-block
//      z-index 1 !important
//      background-position center 60px !important
//      a
//        z-index 1 !important
//  .container
//    overflow hidden
//    background transparent
//    .content
//      overflow hidden
//      //background: url(/images/bg.png) #4c4c4c!important;
//      background #fff !important
//      position: relative !important;
//      margin-top: 285px !important;
//      margin-bottom 0 !important
//      padding-bottom 20px !important
//      padding-top 20px !important
//      @media (min-width 600px)
//        //margin-top: 385px !important;
//      //  background #fff !important
//      //  //background: url(/images/bg.png) #4c4c4c!important;
//      //  margin-top: 385px !important;
//    .footer
//      background #fff !important
//      z-index 2
//      position relative
//
//
//body.branding
//  &:not(.vk-download)
//    .skin-block
//      background-position center 60px !important
//    .container
//      .content
//        margin-top: 200px !important;
//        @media (min-width 600px)
//          margin-top: 385px !important;
//  &.vk-download
//    .skin-block
//      background-position center 110px !important
//    .container
//      .content
//        margin-top: 240px !important;
//        @media (min-width 600px)
//          margin-top: 385px !important;

body.branding
  padding-top 80px
  @media only screen and (min-width : 768px)
    padding-top: 280px;
  background-position-y 62px !important
  >.container
    max-width 1200px
    background white
    .content
      padding-top 20px
      margin-top 0
      &::after {
        content: '';
        display: table;
        clear: both;
      }


.songs .item noindex
  .phone_but
    background-position center
    background-repeat no-repeat
    background-size 20px
    height 20px
    width 20px
    margin-right 5px
    background-image url("/images/svg/gudok.svg")
    &:hover
      background-image url("/images/svg/gudok_hover.svg")
    .rbt_button
      width inherit
      height inherit
      display block
    .mobile &
      margin-right 0