.module-layout
  padding 0

.module-downloaded,
.module-popular
  padding 0 14px
  margin-bottom 20px

  h3, .h3
    margin 24px 0 12px

  ul

    li
      margin-bottom 5px

      a
        padding 9px 8px 9px

    &:after
      cursor pointer
      padding 9px 10px 9px
      vertical-align top

.box
  &.collectionTop
    margin-top 20px
    .plug
      position relative
      overflow hidden
      border-radius 4px
      @media (orientation : landscape)
        margin-right 10px
      .cover_mobile
        position absolute
        width calc(100% - 40px)
        height calc(100% - 40px)
        padding 20px
        left 0
        top 0
        filter none
        z-index 999
    .left
      margin-bottom 0
      padding-bottom 0
      .item.main
        position relative
        margin 0
        h1
          color #000
          font-size 18px
          line-height 22px
        .tags
          .button_tags
            margin-top 0
            margin-bottom 10px
        .button
          float left
          margin-bottom 0
          padding 7px 10px
          z-index 10
          &.favorite
            padding 0
        .shared_buttons.smallRollup
          position initial
          float left
          .show_share
            width 40px
            height 40px
          #share
            bottom 0
            top initial
            z-index -1